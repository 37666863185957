import React, { Component } from "react";

export default class Blog extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-blog" data-section="blog">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div
                className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box"
                data-animate-effect="fadeInLeft"
              >
                <span className="heading-meta">Read</span>
                <h2 className="colorlib-heading">Recent Blog</h2>
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-4 col-sm-6 animate-box"
                data-animate-effect="fadeInLeft"
              >
                <div className="blog-entry">
                  <a
                    href="https://medium.com/nerd-for-tech/understanding-js-under-the-hood-var-let-and-const-f2b2391d4c1a"
                    className="blog-img"
                  >
                    <img
                      src="images/blog-1.png"
                      className="img-responsive"
                      alt="JS Icon"
                      height="200"
                      width="300"
                    />
                  </a>
                  <div className="desc">
                    <span>
                      <small>February 2, 2021 </small> |{" "}
                      <small> JavaScript</small> |{" "}
                      <small>
                        {" "}
                        <i className="icon-bubble3" /> 1
                      </small>
                    </span>
                    <h3>
                      <a
                        href="https://medium.com/nerd-for-tech/understanding-js-under-the-hood-var-let-and-const-f2b2391d4c1a"
                        target="_blank"
                      >
                        Understanding JS under the hood: var, let and const 🦾
                      </a>
                    </h3>
                    <p>
                      Javascript is one of the top programming languages in this
                      2021 and I really love it, that’s why today we’re gonna
                      talk about a topic that sometimes is very complex for
                      beginners.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-md-4 col-sm-6 animate-box"
                data-animate-effect="fadeInRight"
              >
                <div className="blog-entry">
                  <a
                    href="https://jimmyvazz.medium.com/crea-una-presentaci%C3%B3n-cool-para-tu-github-usando-un-repositorio-68a0408dc52b"
                    className="blog-img"
                  >
                    <img
                      src="images/blog-2.png"
                      className="img-responsive"
                      alt="Github Photo"
                      height="900"
                      width="600"
                    />
                  </a>
                  <div className="desc">
                    <span>
                      <small>August 1, 2020 </small> |{" "}
                      <small> Web Development </small> |{" "}
                      <small>
                        {" "}
                        <i className="icon-bubble3" /> 3
                      </small>
                    </span>
                    <h3>
                      <a href="https://jimmyvazz.medium.com/crea-una-presentaci%C3%B3n-cool-para-tu-github-usando-un-repositorio-68a0408dc52b">
                        Crea una presentación cool para tu GitHub usando un
                        repositorio 🧑‍💻
                      </a>
                    </h3>
                    <p>
                      Una de las novedades más geniales que nos ha traído GitHub
                      son las presentaciones de usuario usando repositorios, nos
                      permiten dar una pequeña introducción o presentación del
                      perfil
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-md-4 col-sm-6 animate-box"
                data-animate-effect="fadeInLeft"
              >
                <div className="blog-entry">
                  <a
                    href="https://jimmyvazz.medium.com/el-verdadero-potencial-del-blockchain-moda-o-revoluci%C3%B3n-ca07fef2ea7a"
                    className="blog-img"
                  >
                    <img
                      src="images/blog-3.png"
                      className="img-responsive"
                      alt="Blockchain"
                      height="250"
                      width="450"
                    />
                  </a>
                  <div className="desc">
                    <span>
                      <small>Junio, 2019 </small> | <small> Blockchain </small>{" "}
                      |{" "}
                      <small>
                        {" "}
                        <i className="icon-bubble3" /> 5
                      </small>
                    </span>
                    <h3>
                      <a href="https://jimmyvazz.medium.com/el-verdadero-potencial-del-blockchain-moda-o-revoluci%C3%B3n-ca07fef2ea7a">
                        El verdadero potencial del blockchain, ¿Moda o
                        revolución?
                      </a>
                    </h3>
                    <p>
                      Actualmente te aseguro que en algún evento, pitch, redes
                      sociales o hasta en alguna empresa transnacional has
                      escuchado hablar del Blockchain junto con varios conceptos
                      como Machine Learning, Big Data o IoT.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
		  <a
            className="btn btn-secondary btn-lg btn-learn"
            href="https://jimmyvazz.medium.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read more stories on my blog
            <i className="icon-arrow-right" />
          </a>
        </section>
      </div>
    );
  }
}
