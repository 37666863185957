import React from "react";

export default function ArtisticCareer({ title, subtitle }) {
  return (
    <div>
      <section className="colorlib-blog" data-section="artistic-career">
        <div className="colorlib-narrow-content">
          <div className="row">
            <div
              className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box"
              data-animate-effect="fadeInLeft"
            >
              <span className="heading-meta">{title}</span>
              <h2 className="colorlib-heading">{subtitle}</h2>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-4 col-sm-6 animate-box"
              data-animate-effect="fadeInLeft"
            >
              <div className="blog-entry">
                {/* <a
                  href="https://medium.com/nerd-for-tech/understanding-js-under-the-hood-var-let-and-const-f2b2391d4c1a"
                  className="blog-img"
                > */}
                <img
                  src={require("../assets/images/QuienAndaAhi.jpeg")}
                  className="img-responsive"
                  alt="¿Quien anda ahi? - Cover"
                  height="200"
                  width="300"
                />
                {/* </a> */}
                <div className="desc">
                  <span>
                    <small>Enero, 2025 </small> | <small> Microcuento </small>
                    {/* <small>
                      <i className="icon-bubble3" /> 1
                    </small> */}
                  </span>
                  <h3>
                    {/* <a
                      href="https://medium.com/nerd-for-tech/understanding-js-under-the-hood-var-let-and-const-f2b2391d4c1a"
                      target="_blank"
                    > */}
                    ¿Quién esta ahí?
                    {/* </a> */}
                  </h3>
                  <p>
                    —¡No manches! Te pasas, Odín. Te dije que no anduvieras
                    jalando la basura. Ven para acá, «cara de perro», te vas
                    pa'fuera ... Me agaché y lo abracé con lágrimas en los ojos.
                    Lo junté a mi pecho. Solo quería disfrutar el momento antes
                    de despertar.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-4 col-sm-6 animate-box"
              data-animate-effect="fadeInRight"
            >
              <div className="blog-entry">
                <a
                  href="https://medium.com/@jimmyvazz/10-minutos-15673d4913fc"
                  className="blog-img"
                >
                  <img
                    src={require("../assets/images/10Minutos.jpeg")}
                    className="img-responsive"
                    alt="10 minutos - Cover"
                    height="900"
                    width="600"
                  />
                </a>
                <div className="desc">
                  <span>
                    <small>Enero, 2025 </small> |{" "}
                    <small> Relato Cinematográfico </small>
                    {/* <small>
                      {" "}
                      <i className="icon-bubble3" /> 3
                    </small> */}
                  </span>
                  <h3>
                    <a href="https://medium.com/@jimmyvazz/10-minutos-15673d4913fc">
                      10 minutos
                    </a>
                  </h3>
                  <p>
                    Lizbeth es una chica que solo quiere llegar al trabajo, una
                    tarea que es más difícil día con día.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-4 col-sm-6 animate-box"
              data-animate-effect="fadeInLeft"
            >
              <div className="blog-entry">
                {/* <a
                  href="https://jimmyvazz.medium.com/el-verdadero-potencial-del-blockchain-moda-o-revoluci%C3%B3n-ca07fef2ea7a"
                  className="blog-img"
                > */}
                <img
                  src={require("../assets/images/Foraneo1.jpeg")}
                  className="img-responsive"
                  alt="El foraneo - Cover"
                  height="250"
                  width="450"
                />
                {/* </a> */}
                <div className="desc">
                  <span>
                    <small>Marzo 2025</small> | <small> Cortometraje </small> |{" "}
                    Próximamente
                    {/* <small>
                      {" "}
                      <i className="icon-bubble3" /> 5
                    </small> */}
                  </span>
                  <h3>
                    {/* <a href="https://jimmyvazz.medium.com/el-verdadero-potencial-del-blockchain-moda-o-revoluci%C3%B3n-ca07fef2ea7a"> */}
                    El foráneo
                    {/* </a> */}
                  </h3>
                  <p>
                    Alejandro lucha contra un sistema que ha marginado el sueño
                    de salir adelante, cayendo en una realidad que nadie quiere
                    saber.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <a
            className="btn btn-secondary btn-lg btn-learn"
            href="https://jimmyvazz.medium.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read more stories on my blog
            <i className="icon-arrow-right" />
          </a>
        </div>
      </section>
    </div>
  );
}
